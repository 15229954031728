@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #faf9f6 !important;
  overflow-x: hidden !important;
  max-width: 100vw;
  width: 100%;
}

body::-webkit-scrollbar {
  width: 13px;
}

body::-webkit-scrollbar-track {
  background: #4c4c4c;
}

body::-webkit-scrollbar-thumb {
  background-color: #171717;
  border-radius: 20px;
  border: 3px solid #4c4c4c;
}

.nav-animate {
  position: relative;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  color: rgba(255, 255, 255, 0.9);
}
.nav-animate::after {
  display: block;
  position: relative;
  top: 6px;
  content: "";
  width: inherit;
  border-bottom: solid 3px red;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  transform-origin: left;
}
.nav-animate:hover::after {
  transform: scaleX(1);
}
.nav-animate.active::after {
  transform: scaleX(1);
}

.nav-animate:hover {
  color: #fff;
}

.truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.circle-1 {
  position: fixed;
  right: 0;
  transform: translateX(50%);
  bottom: 100px;
  background-color: red;
  height: 500px;
  width: 500px;
  border-radius: 50%;
  filter: blur(50px);
  opacity: 0.2;
  z-index: -1;
}

.circle-2 {
  position: fixed;
  left: 0;
  transform: translateX(-50%);
  bottom: -100px;
  background-color: red;
  height: 300px;
  width: 300px;
  border-radius: 50%;
  filter: blur(50px);
  opacity: 0.25;
  z-index: -1;
}

@media screen and (min-width: 767px) {
  .carousel-content::before {
    content: "";
    position: absolute;
    top: 0;
    left: -49px;
    width: 50px;
    height: 100%;
    background: linear-gradient(to bottom right, transparent 0%, transparent 50%, #ff6b6b 50%, #ff6b6b 100%);
  }
  .carousel-image::before {
    content: "";
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
  }
}
@media screen and (max-width: 767px) {
  .carousel-image::before {
    content: "";
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
}

@media (min-width: 1800px) {
  .h-auto-3xl {
    height: auto;
  }
}

.slick-dots li button:before {
  color: #d9184b;
}
.slick-dots li.slick-active button:before {
  color: #d9184b;
}
.slick-slide > div {
  margin: 0 10px;
  border-radius: 40px;
}
.slick-slide:not(.slick-current) > div {
  opacity: 0.9;
  transform: scale(0.9);
  transition: all 0.3s ease;
}

.BlockContent h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #000;
  margin-bottom: 1rem;
}
.BlockContent h2 {
  font-size: 2rem;
  font-weight: 700;
  color: #000;
  margin-bottom: 1rem;
}
.BlockContent h3 {
  font-size: 1.8rem;
  font-weight: 700;
  color: #000;
  margin-bottom: 1rem;
}
.BlockContent h4 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #000;
  margin-bottom: 1rem;
}
.BlockContent h5 {
  font-size: 1.3rem;
  font-weight: 700;
  color: #000;
  margin-bottom: 1rem;
}
.BlockContent h6 {
  font-size: 1.2rem;
  font-weight: 700;
  color: #000;
  margin-bottom: 1rem;
}
.BlockContent p {
  font-size: 1rem;
  color: #000;
  margin-bottom: 1rem;
}

.BlockContent a {
  color: rgb(78, 78, 224);
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
}
.BlockContent a:hover {
  text-decoration: underline;
}

.BlockContent ul,
.BlockContent ol {
  list-style: disc;
  padding-inline: 1rem;
  margin-bottom: 1rem;
}

.BlockContent ul li,
.BlockContent ol li {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: black;
}

@media screen and (max-width: 768px) {
  .BlockContent h1 {
    font-size: 2rem;
  }
  .BlockContent h2 {
    font-size: 1.8rem;
  }
  .BlockContent h3 {
    font-size: 1.6rem;
  }
  .BlockContent h4 {
    font-size: 1.4rem;
  }
  .BlockContent h5 {
    font-size: 1.2rem;
  }
  .BlockContent h6 {
    font-size: 1rem;
  }
  .BlockContent p {
    font-size: 0.9rem;
  }
  .BlockContent ul,
  .BlockContent ol {
    padding-inline: 0.5rem;
  }
  .BlockContent ul li,
  .BlockContent ol li {
    font-size: 0.9rem;
  }
}

/* Error page */

.denied__wrapper {
  max-width: 390px;
  width: 100%;
  height: 390px;
  display: block;
  margin: 0 auto;
  position: relative;
  margin-top: 8vh;
}
#astronaut {
  width: 43px;
  position: absolute;
  right: 20px;
  top: 210px;
  animation: spin 4.5s infinite linear;
}
.permission_denied h1 {
  text-align: center;
  color: #292929;
  font-family: "Dosis", sans-serif;
  font-size: 100px;
  margin-bottom: 0px;
  font-weight: 800;
}

.permission_denied h3 {
  text-align: center;
  color: #292929;
  font-size: 19px;
  line-height: 23px;
  max-width: 330px;
  margin: 0px auto 30px auto;
  font-family: "Dosis", sans-serif;
  font-weight: 400;
}

.permission_denied h3 span {
  position: relative;
  width: 65px;
  display: inline-block;
}

.permission_denied h3 span:after {
  content: "";
  border-bottom: 3px solid #d9184b;
  position: absolute;
  left: 0;
  top: 43%;
  width: 100%;
}

.denied__link {
  background: none;
  color: #292929;
  padding: 12px 0px 10px 0px;
  border: 1px solid #292929;
  outline: none;
  border-radius: 7px;
  width: 150px;
  font-size: 15px;
  text-align: center;
  margin: 0 auto;
  vertical-align: middle;
  display: block;
  margin-bottom: 40px;
  margin-top: 25px;
  font-family: "Dosis", sans-serif;
  font-weight: 400;
}

.denied__link:hover {
  color: #d9184b;
  border-color: #d9184b;
  cursor: pointer;
  opacity: 1;
}

@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@media (max-width: 600px) {
  .permission_denied h1 {
    font-size: 75px;
  }
  .permission_denied h3 {
    font-size: 16px;
    width: 200px;
    margin: 0 auto;
    line-height: 23px;
  }
  .permission_denied h3 span {
    width: 60px;
  }
  #astronaut {
    width: 35px;
    right: 40px;
    top: 170px;
  }
}

.saturn,
.saturn-2,
.hover {
  animation: hover 2s infinite ease-in-out alternate;
}

@keyframes hover {
  0% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(-3px);
  }
}

.basic-transition {
  transition:
    opacity 0.5s,
    transform 0.5s;
}

.basic-transition.preEnter,
.basic-transition.exiting {
  opacity: 0;
  transform: translateY(200px) scale(0.5);
}

.basic-transition.exited {
  display: none;
}

.team-card .icons li {
  list-style: none;
  transform: translateY(40px);
  transition: all 0.5s;
  opacity: 0;
  transition-delay: calc(0.2s * var(--i));
}

.team-card:hover .icons li {
  transform: translateY(0);
  opacity: 1;
}
.team-card .icons li a {
  color: white;
  font-size: 24px;
}

/* under construction */
.main-title {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.main-title h1 {
  font-size: 5rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #ff6b6b;
}

.main-title h2 {
  font-size: 1.9rem;
  font-weight: 300;
  text-transform: uppercase;
  color: #ff6b6b;
}

.svg-img {
  display: block;
  margin: auto;
  max-width: 500px;
  position: relative;
}

.svg-img svg {
  display: block;
  margin: auto;
}

#clock {
  animation: clockHand 5s infinite linear;

  transform-box: fill-box;
  transform-origin: bottom;
}

#leftTree,
#righTree {
  animation: tree 2s ease-in-out infinite alternate;
  transform-box: fill-box;
  transform-origin: bottom;
}

#man {
  animation: manBody 1s ease-in-out infinite alternate;
  transform-box: fill-box;
  transform-origin: bottom;
}

#pc-circle {
  fill: #6ace66;
  stroke-width: 4;
  animation: change-light 4s linear infinite alternate;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 35px;
  line-height: 35px;
  background-color: #f5f5f5;
  font-size: 1.3rem;
}

@keyframes clockHand {
  from {
    transform: rotateZ(0deg);
  }
  from {
    transform: rotateZ(-360deg);
  }
}

@keyframes manBody {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(10deg);
  }
}

@keyframes tree {
  from {
    transform: rotateZ(10deg);
  }
  to {
    transform: rotateZ(-20deg);
  }
}

@keyframes change-light {
  0% {
    stroke: #cd61f8;
  }
  25% {
    stroke: #6ace66;
  }
  75% {
    stroke: #2995c0;
  }
  100% {
    stroke: #e92949;
  }
}
